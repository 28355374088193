import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import {EventType} from '../../../constants/propTypesDefinitions';
import {createFetchEventRemoveType} from '../../../backend/apiCalls';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class EventTypeRemove extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        eventType: EventType.isRequired,
    };

    render() {
        const {eventType = {}} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie typu eventov</Trans>}>
                    <Button className="mx-1" size="small" type="danger" icon="delete"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={() => {reloadData([GLOBAL_DATA.EVENT_TYPES])}}
            title={<Trans>Odstránenie typu eventu</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť typ eventu?</Trans>,
                createFetchEventRemoveType(eventType.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť typ eventu.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(EventTypeRemove);